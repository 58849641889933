import React, { useEffect, useState } from "react";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as RightArrow } from "../../../assets/Icons/pageDownArrow.svg";
import SwiperContainer from "./SwiperContainer";
import {useWindowWidth} from '@react-hook/window-size'
import { useTranslation } from 'react-i18next';
import { rowItemCount, translateText } from "../../../utils/utils";

const Categories = ({ data, thumbnailOrientation,type,lastChild }) => {
  const { t } = useTranslation();
  const languageModal = useSelector((state) => state?.languageModal?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const isCardHover = useSelector((state) => state?.overlay?.value);
  const navigate = useNavigate();
  const [showSlider, setShowSlider] = useState(false);
  const [cardHover,setCardHover] = useState(false)
  const [rowItemsClass,setRowItemsClass] = useState("")

  const windowSize = useWindowWidth()

  useEffect(() => {
    setTimeout(() => setShowSlider(true), 500);
  }, []);
  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount6")
      }
    }else{
      setRowItemsClass("")
    }
  }, [projectInfo,windowSize]);

  const swiperHandler=data?.shows?.slice(0,17).map((item, index) => {
    return(

    <SwiperSlide 
      key={index} 
      onMouseEnter={()=>setCardHover(true)} 
      onMouseLeave={()=>setCardHover(false)}
      className={`hover ${thumbnailOrientation === "PORTRAIT"?`portrait`:`landscape`}`}
      >
      <SwiperContainer categoryData={data} data={item} showCount={data?.shows?.length} type={type} index={index} />
    </SwiperSlide>
    )
})

  return (
    <div className={cardHover?`categoriesContainer hideArrow ${lastChild&&`lastChild`} ${thumbnailOrientation === "PORTRAIT"?`portrait`:`landscape`} ${rowItemsClass}`:`categoriesContainer ${thumbnailOrientation == "PORTRAIT"?`portrait`:`landscape` } ${lastChild&&`lastChild`} ${rowItemsClass}`}>
      <div
        className={showSlider ? "showSlider itemsContainer" : "itemsContainer"}
      >
        <div
          className="categoryNameContainer"
          onClick={() =>
            navigate(`/category/${data?.key}?career=${data?.key}`, {
              state: { careers: data?.key, type: data?.type },
            })
          }
        >
          <div className="content">

            <h1>{data?.category_name}</h1>
            <div className="exploreAll">
              <div className="seeAll">{translateText(languageModal, t, 'explore_all')}</div>
              <div className="rightArrow">
                <RightArrow />
              </div>
            </div>
          </div>
        </div>
        <Swiper
          modules={[Navigation]}
          spaceBetween={1.5}
          slidesPerView={6}
          slidesPerGroup={6}
          navigation={true}
          watchSlidesProgress
          speed={1000}
          loop={false}
          breakpoints={{
            320: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 2 : 1,
              spaceBetween: 2,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 2 : 1,
            },
            360: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 3 : 3,
              spaceBetween: 2,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 3 : 3,
            },
            480: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 3 : 3,
              spaceBetween: 5,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 3 : 3,
            },
            640: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 4 : 3,
              spaceBetween: 5,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 4 : 3,
            },
            768: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 4 : 4,
              spaceBetween: 5,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 4 : 4,
            },
            980: {
              slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):4,
              spaceBetween: 5,
              slidesPerGroup:projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):4

            },
            981: {
              slidesPerView: thumbnailOrientation == "PORTRAIT" ? 6 : 5,
              spaceBetween: 5,
              slidesPerGroup:thumbnailOrientation == "PORTRAIT" ? 6 : 5
            },
            1200: {
             
              slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):7,
              spaceBetween: 5,
              slidesPerGroup:projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):7
            },
            2200:{
              slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):8,
              spaceBetween: 5,
              slidesPerGroup:projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?Number(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT):8

            }
          }}
        >
          {swiperHandler}
          {/* <SwiperSlide>
            <div className="showMoreCard">
              <h2>Show All ({data && data?.shows?.length})</h2>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </div>
  );
};

export default Categories;
