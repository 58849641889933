
import React from "react";
import { useNavigate ,useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import Translate from "../MultiLanguage/Translate";
const ContinueWithSubscriptionModal = (props) => {

  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state?.user?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const ContinueWatchingHandler = async (value) => {
    if (value === "yes") {
      props?.setIsContinueWithSubscriptionModal(false);
      localStorage.setItem('initialLoaded', 'true');

      if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
        navigate("/checkout", { state: { channelId: props?.channelData?.channel_id } });
      } else {

        if (!user) {
          navigate("/login", { state: { path: location?.pathname } });
        } else {
          navigate("/subscription", {
            state: { channelId: props?.channelData?.channel_id },
          });
        }
      }

    } else if (value === "no") {
      props?.setIsContinueWithSubscriptionModal(false);

    }
  };
  return (
    <div className="continueWatching">
      <div className="overlayModal"></div>
      <div className="contents">
        <h1>
        { projectInfo?.projectConfig?.config?.MULTI_LANGUAGE_REQUIRED === 'true'
            ? (
              <>
                <Translate textKey="proceed_with_subscription" /> {props?.channelData?.channel_name}
              </>
            )
            : `Would you like to proceed with watching by subscribing to the '${props?.channelData?.channel_name}' channel?`
        }
      </h1>

        <div className="buttons">
          <div
            className="buttonContainer"
            onClick={() => ContinueWatchingHandler("yes")}
          >
            <div className="background"></div>
            <button><Translate textKey={'yes'} /></button>
          </div>

          <div
            className="buttonContainer"
            onClick={() => ContinueWatchingHandler("no")}
          >
            <div className="background"></div>
            <button><Translate textKey={'no'} /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueWithSubscriptionModal;
