import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import IosLandingVideo from './IosLandingVideo';
import { checkOperatingSystem } from '../../utils/utils';
function LandingScreenVideo() {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const navigate = useNavigate();
    const [isIPhone,setIsIphone] = useState()

    const storeButtonHandler = (storeLink) => {
        window.location.href = storeLink
    }
    const bannerButtonClickHandler = () => {
        navigate('/home')
    }
    useEffect(() => {
        setIsIphone(checkOperatingSystem() === "iPhone")
    }, []);
    return (
        <div className="landingScreen">
            <div className="videoBanner">
                {isIPhone? <IosLandingVideo/>:
                <video autoPlay muted loop id="background-video">
                    <source src={projectInfo?.projectConfig?.config?.LANDING_PAGE_VIDEO} type="video/mp4" />
                </video>  }
                <button
                    className='bannerButton'
                    onClick={bannerButtonClickHandler}>Start Watching</button>
            </div>
            {(projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL || projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL || projectInfo?.projectConfig?.config?.ANDROID_TV_PLAY_STORE_URL || projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL || projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL || projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL) &&
                <div className="deviceList">
                    <div className="left">
                        <h1 className="heading">Your favorite channels all in one place</h1>
                        <p className="description">Discover your favorite channels all in one place with Cinemavault. Stream top shows and movies seamlessly with add-on subscriptions to the channels you love</p>
                    </div>
                    <div className="right">
                        <ul className="devices">
                            {projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.ROKU_CHANNEL_STORE_URL) }}><span>Roku</span></li>}
                            {projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.APPLE_TV_APP_STORE_URL) }}><span>Apple TV</span></li>}
                            {projectInfo?.projectConfig?.config?.ANDROID_TV_PLAY_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.ANDROID_TV_PLAY_STORE_URL) }}><span>Android TV</span></li>}
                            {projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.ANDROID_PLAY_STORE_URL) }}><span>Android</span></li>}
                            {projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.IOS_APP_STORE_URL) }}><span>iOS</span></li>}
                            {projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL && <li onClick={() => { storeButtonHandler(projectInfo?.projectConfig?.config?.FIRE_TV_APPSTORE_URL) }}><span>Amazon Fire</span></li>}

                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}

export default LandingScreenVideo